export const isClient = typeof window === "object"

export const isInTimeRange = (start, end, time) => {
  return parseInt(end, 10) > time && parseInt(start, 10) <= time
}

export function unique(array) {
  function onlyUnique(value, index, self) {
    return self.indexOf(value) === index
  }
  return [...array.filter(onlyUnique)]
}

export function remove(array, value) {
  const newArray = [...array]
  return newArray.filter(function(item) {
    return item !== value
  })
}

import PropTypes from "prop-types"
import React from "react"

export const NotificationStateContext = React.createContext({})
export const NotificationDispatchContext = React.createContext({})

export const useNotificationState = () => {
  const context = React.useContext(NotificationStateContext)
  if (context === undefined) {
    throw new Error(
      "useNotificationState must be used within a NotificationContextProvider"
    )
  }
  return context
}

export const useNotificationDispatch = () => {
  const context = React.useContext(NotificationDispatchContext)
  if (context === undefined) {
    throw new Error(
      "useNotificationDispatch must be used within a NotificationContextProvider"
    )
  }
  return context
}

export const NotificationContextProvider = ({ children }) => {
  const [notification, setNotification] = React.useState(null)

  React.useEffect(() => {
    if (!notification || typeof notification === "object") {
      return
    }
    const timeout = setTimeout(() => setNotification(null), 1500)
    return () => {
      clearTimeout(timeout)
    }
  }, [notification])

  return (
    <NotificationStateContext.Provider value={notification}>
      <NotificationDispatchContext.Provider value={setNotification}>
        {children}
      </NotificationDispatchContext.Provider>
    </NotificationStateContext.Provider>
  )
}

NotificationContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}

import ReactGA from "react-ga"

import { isClient } from "../lib/util"

export function init(id) {
  if (isClient && !window.GA_INITIALIZED) {
    window.GA_INITIALIZED = true
    ReactGA.initialize(id, {
      // debug: process.env.NODE_ENV !== "production",
    })
  }
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = url => {
  if (isClient) {
    window.setTimeout(() => {
      ReactGA.set({ page: url, title: document.title })
      ReactGA.pageview(url)
    }, 0)
  }
}

import PropTypes from "prop-types"
import React from "react"
import { useLocalStorage } from "react-use"
import Shopify from "shopify-buy"

const SESSION_KEY = "lafilledo/shopify-checkout-id"
const client = Shopify.buildClient({
  domain: "la-fille-do.myshopify.com",
  storefrontAccessToken: "ef468db1bfd913296bc6e8209515527b",
})

export const CartStateContext = React.createContext({})
export const CartDispatchContext = React.createContext({})

const cartReducer = (state, action) => {
  switch (action.type) {
    case "show": {
      return {
        ...state,
        show: true,
      }
    }
    case "hide": {
      return {
        ...state,
        show: false,
      }
    }
    case "update": {
      return {
        ...state,
        checkout: action.payload,
      }
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`)
    }
  }
}

const initialState = {
  show: false,
  checkout: null,
}

export const useCartState = () => {
  const context = React.useContext(CartStateContext)
  if (context === undefined) {
    throw new Error("useCartState must be used within a CartContextProvider")
  }
  return context
}

export const useCartDispatch = () => {
  const context = React.useContext(CartDispatchContext)
  if (context === undefined) {
    throw new Error("useCartDispatch must be used within a CartContextProvider")
  }
  return context
}

export const useCartContext = () => {
  return [useCartState(), useCartDispatch()]
}

export const addCartItem = async (dispatch, state, variant) => {
  const checkout = await client.checkout.addLineItems(state.checkout.id, [
    {
      variantId: btoa(`gid://shopify/ProductVariant/${variant.id}`),
      quantity: 1,
    },
  ])
  dispatch({ type: "update", payload: checkout })
}

export const removeCartItem = async (dispatch, state, variant) => {
  const checkout = await client.checkout.removeLineItems(state.checkout.id, [
    variant.id,
  ])
  dispatch({ type: "update", payload: checkout })
}

export const showCart = dispatch => {
  dispatch({ type: "show" })
}

export const hideCart = dispatch => {
  dispatch({ type: "hide" })
}

export const CartContextProvider = ({ children }) => {
  const [value, dispatch] = React.useReducer(cartReducer, initialState)
  const [checkoutId, setCheckoutId] = useLocalStorage(SESSION_KEY)

  React.useEffect(() => {
    let isCancelled = false
    const initialize = async () => {
      let checkout
      if (checkoutId) {
        // fetch existing checkout
        checkout = await client.checkout.fetch(checkoutId)
      }
      if (!checkout || checkout.completedAt !== null) {
        // checkout not found or completed, create new checkout
        checkout = await client.checkout.create()
      }
      if (checkout && !isCancelled) {
        // save checkout
        dispatch({ type: "update", payload: checkout })
        setCheckoutId(checkout.id)
      }
    }
    initialize()
    return () => {
      isCancelled = true
    }
  }, [checkoutId, setCheckoutId])

  return (
    <CartStateContext.Provider value={value}>
      <CartDispatchContext.Provider value={dispatch}>
        {children}
      </CartDispatchContext.Provider>
    </CartStateContext.Provider>
  )
}

CartContextProvider.propTypes = {
  children: PropTypes.node.isRequired,
}
